import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import classNames from 'classnames/bind';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './App.module.scss';
import Sidebar from './components/layout/Sidebar';
import FontLoader from './helpers/FontLoader';
import ProtectedRoute from './helpers/ProtectedRoute';
import UnprotectedRoute from './helpers/UnprotectedRoute';
import Home from './pages/home';
import Login from './pages/login';
import AccountList from './pages/accounts';
import AccountNew from './pages/accounts/new';
import AccountDetail from './pages/accounts/detail';
import ModelList from './pages/models';
import ModelNew from './pages/models/new';
import ModelDetail from './pages/models/detail';
import CommandList from './pages/commands';
import CommandNew from './pages/commands/new';
import CommandDetail from './pages/commands/detail';
import TelemetryNew from './pages/models/telemetries/new';
import TelemetryDetail from './pages/models/telemetries/detail';
import RobotList from './pages/robots';
import RobotNew from './pages/robots/new';
import RobotDetail from './pages/robots/detail';
import MetadataNew from './pages/robots/metadata/new';
import MetadataDetail from './pages/robots/metadata/detail';

const cx = classNames.bind(styles);

const App = () => {
  return (
    <BrowserRouter>
      <FontLoader />
      <div className={cx('container')}>
        <ToastContainer
          closeOnClick
          newestOnTop
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          style={{ width: '480px' }}
        />
        <Sidebar />
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <AccountList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <AccountNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <AccountDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <ModelList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <ModelNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <ModelDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/commands"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <CommandList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/commands/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <CommandNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/commands/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <CommandDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/robots"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <RobotList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/robots/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <RobotNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/robots/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <RobotDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/robots/:id/metadata/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <MetadataNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/robots/:id/metadata/:mid"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <MetadataDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models/:id/telemetries/new"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <TelemetryNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/models/:id/telemetries/:tid"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <TelemetryDetail />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
