import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { IoCloseOutline, IoCheckmarkOutline } from 'react-icons/io5';

import styles from './SearchCommand.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';

const cx = classNames.bind(styles);

const Modal = ({ callback }) => {
  const [commands, setCommands] = useState();

  useEffect(() => {
    API.get('/commands').then(({ success, data }) => {
      if (success) {
        setCommands(data.filter(({ sysId }) => sysId !== null));
      } else {
        setCommands([]);
      }
    });
  }, []);

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => <span className={cx('monospace')}>{item.name}</span>,
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'sysId',
      name: 'SYS ID',
      exp: (item) => item.sysId,
      width: 88,
      align: 'center',
      onPress: callback,
    },
    {
      key: 'compId',
      name: 'COMP ID',
      exp: (item) => item.compId,
      width: 88,
      align: 'center',
      onPress: callback,
    },
    {
      key: 'isCommanderOnly',
      name: 'Command',
      exp: (item) => (item.isCommanderOnly ? <IoCheckmarkOutline size={22} /> : '-'),
      width: 88,
      align: 'center',
      onPress: callback,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Commands</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={commands} />
      </div>
    </div>
  );
};

export default Modal;
