import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { IoCloseOutline } from 'react-icons/io5';
import commaNumber from 'comma-number';

import styles from './SearchAccount.module.scss';
import API from '../../helpers/API';
import Table from '../ui/Table';

const cx = classNames.bind(styles);

const Modal = ({ callback }) => {
  const [accounts, setAccounts] = useState();

  useEffect(() => {
    API.get('/accounts').then(({ success, data }) => {
      if (!success) {
        setAccounts([]);
        return;
      }

      data.sort((a, b) => {
        // company가 null일 경우, 빈 문자열로 대체하여 정렬
        const companyA = a.company || '';
        const companyB = b.company || '';

        if (companyA > companyB) return 1;
        if (companyA < companyB) return -1;

        // company가 같으면 name을 기준으로 오름차순 정렬
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;

        return 0;
      });
      setAccounts(data);
    });
  }, []);

  const close = () => {
    callback(null);
  };

  const columns = [
    {
      key: 'company',
      name: 'Company',
      exp: (item) => item.company ?? '-',
      width: 200,
      onPress: callback,
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      width: 120,
      onPress: callback,
    },
    {
      key: 'email',
      name: 'Email',
      exp: (item) => item.email ?? '-',
      style: { flex: 1 },
      onPress: callback,
    },
    {
      key: 'robots',
      name: 'Robots',
      exp: (item) => commaNumber(item.robots.length),
      width: 88,
      align: 'center',
      onPress: callback,
    },
  ];

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Accounts</div>
        <div className={cx('close')} onClick={close}>
          <IoCloseOutline size={24} color="white" />
        </div>
      </div>
      <div className={cx('body')}>
        <Table columns={columns} data={accounts} />
      </div>
    </div>
  );
};

export default Modal;
