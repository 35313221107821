import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import { IoImageOutline } from 'react-icons/io5';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState();

  useEffect(() => {
    // API: 모델 내역 조회
    API.get('/models').then(({ success, data }) => {
      if (success) {
        data.sort((a, b) => {
          if (a.maker > b.maker) return 1;
          if (a.maker < b.maker) return -1;

          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;

          return 0;
        });
        setInfo(data);
      }
    });

    setInfo([]);
  }, []);

  const moveToForm = () => {
    navigate('/models/new');
  };

  const columns = [
    {
      key: 'id',
      name: 'ID',
      exp: (item) => <span className={cx('monospace')}>{item.id.substring(0, 8)}</span>,
      align: 'center',
      width: 120,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.id;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.maker,
      style: { flex: 1 },
      onPress: (item) => navigate(`/models/${item.id}`),
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
      onPress: (item) => navigate(`/models/${item.id}`),
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.agentType ?? '-',
      width: 120,
      align: 'center',
      onPress: (item) => navigate(`/models/${item.id}`),
    },
    {
      key: 'iconUrl',
      name: 'Icon',
      exp: (item) => (item.iconUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.iconUrl && window.open(item.iconUrl),
    },
    {
      key: 'thumbnailUrl',
      name: 'Thumb',
      exp: (item) => (item.thumbnailUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.thumbnailUrl && window.open(item.thumbnailUrl),
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>{info && `Total ${commaNumber(info.length)}`}</TopBar>
      <ContentWrapper>
        <Table columns={columns} data={info} />
      </ContentWrapper>
      <BottomBar>
        <div />
        <div>
          <Button title="New" onClick={moveToForm} />
        </div>
      </BottomBar>
    </div>
  );
};

export default Page;
