import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './new.module.scss';
import API from '../../helpers/API';
import TextInput from '../../components/ui/TextInput';
import Button from '../../components/ui/Button';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';
import FormRow from '../../components/ui/FormRow';
import FormRowInfo from '../../components/ui/FormRowInfo';
import FormSectionTitle from '../../components/ui/FormSectionTitle';

const cx = classNames.bind(styles);

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: '',
    sysId: '',
    compId: '',
    memo: '',
  });

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('이름이 입력되지 않았습니다.');
      return false;
    }
    if (info.sysId.trim() === '') {
      toast.error('SYS ID가 입력되지 않았습니다.');
      return false;
    }
    if (info.compId.trim() === '') {
      toast.error('COMP ID가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      name: info.name,
      sysId: info.sysId,
      compId: info.compId,
      memo: info.memo,
    };

    API.post('/commands', data).then(({ success }) => {
      if (success) {
        navigate('/commands');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <TopBar>New Command</TopBar>
      <ContentWrapper>
        <FormRow title="Name" required>
          <TextInput name="name" width={320} value={info.name} placeholder="Name" onChange={handleChange} />
        </FormRow>
        <FormRow title="SYS ID" required>
          <TextInput name="sysId" width={96} value={info.sysId} placeholder="SYS ID" onChange={handleChange} />
        </FormRow>
        <FormRow title="COMP ID" required>
          <TextInput name="compId" width={96} value={info.compId} placeholder="COMP ID" onChange={handleChange} />
        </FormRow>
        <FormRow title="Memo">
          <TextInput name="memo" width="100%" value={info.memo} placeholder="Memo" onChange={handleChange} />
        </FormRow>
        <FormSectionTitle title="MavLink Messages" />
        <FormRow title="Message">
          <FormRowInfo>등록 후 설정할 수 있습니다.</FormRowInfo>
        </FormRow>
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
