import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import { IoCheckmarkOutline } from 'react-icons/io5';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [commands, setCommands] = useState();

  useEffect(() => {
    // API: 명령 내역 조회
    API.get('/commands').then(({ success, data }) => {
      if (success) {
        setCommands(data.filter(({ sysId }) => sysId !== null));
      } else {
        setCommands([]);
      }
    });
  }, []);

  const moveToForm = () => {
    navigate('/commands/new');
  };

  const columns = [
    {
      key: 'id',
      name: 'ID',
      exp: (item) => <span className={cx('monospace')}>{item.id.substring(0, 8)}</span>,
      align: 'center',
      width: 120,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.id;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => <span className={cx('monospace')}>{item.name}</span>,
      style: { flex: 1 },
      onPress: (item) => navigate(`/commands/${item.id}`),
    },
    {
      key: 'sysId',
      name: 'SYS ID',
      exp: (item) => item.sysId,
      width: 88,
      align: 'center',
      onPress: (item) => navigate(`/commands/${item.id}`),
    },
    {
      key: 'compId',
      name: 'COMP ID',
      exp: (item) => item.compId,
      width: 88,
      align: 'center',
      onPress: (item) => navigate(`/commands/${item.id}`),
    },
    {
      key: 'isCommanderOnly',
      name: 'Command',
      exp: (item) => (item.isCommanderOnly ? <IoCheckmarkOutline size={22} /> : '-'),
      width: 88,
      align: 'center',
      onPress: (item) => navigate(`/commands/${item.id}`),
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>{commands && `Total ${commaNumber(commands.length)}`}</TopBar>
      <ContentWrapper>
        <Table columns={columns} data={commands} />
      </ContentWrapper>
      <BottomBar>
        <div />
        <div>
          <Button title="New" onClick={moveToForm} />
        </div>
      </BottomBar>
    </div>
  );
};

export default Page;
