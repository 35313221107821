import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { toast } from 'react-toastify';

import styles from './CommandList.module.scss';
import API from '../../../helpers/API';
import Table from '../../../components/ui/Table';
import SearchCommand from '../../../components/modals/SearchCommand';

const cx = classNames.bind(styles);

const CommandList = ({ agentType }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [commands, setCommands] = useState();
  const [showCommandList, setShowCommandList] = useState(false);

  useEffect(() => {
    // API: 명령 내역 조회
    API.get(`/models/${id}/v2/commands`).then(({ success, data }) => {
      if (success) {
        setCommands(data.sort((a, b) => (a.name < b.name ? -1 : 1)));
      }
    });
  }, [id]);

  const toggleCommandList = () => {
    setShowCommandList(!showCommandList);
  };

  const addCommand = (item) => {
    toggleCommandList();

    if (item) {
      API.post(`/models/${id}/v2/commands`, [item.id]).then(({ success, data }) => {
        if (success) {
          setCommands([...commands, item]);
        } else {
          toast.error('추가를 실패했습니다.');
        }
      });
    }
  };

  const doDelete = (item) => {
    if (!confirm('삭제하시겠습니까?')) return;

    // API: 명령삭제
    API.delete(`/models/${id}/v2/commands/${item.id}`).then(({ success }) => {
      if (success) {
        setCommands(commands.filter((command) => command.id !== item.id));
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const columnsForMavLink = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => <span className={cx(['monospace', 'blue'])}>{item.name}</span>,
      style: { flex: 1 },
      onPress: (item) => navigate(`/commands/${item.id}`),
    },
    {
      key: 'sysId',
      name: 'SYS ID',
      exp: (item) => item.sysId,
      width: 88,
      align: 'center',
    },
    {
      key: 'compId',
      name: 'COMP ID',
      exp: (item) => item.compId,
      width: 88,
      align: 'center',
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: doDelete,
    },
  ];

  const columnsForROS = [
    {
      key: 'name',
      name: 'Name',
      exp: (item) => <span className={cx(['monospace', 'blue'])}>{item.name}</span>,
      style: { flex: 1 },
    },
    {
      key: 'topic',
      name: 'Topic',
      exp: (item) => <span className={cx('monospace')}>{item.topic}</span>,
      width: 280,
    },
    {
      key: 'type',
      name: 'Type',
      exp: (item) => <span className={cx('monospace')}>{item.type}</span>,
      width: 320,
    },
    {
      key: 'delete',
      name: 'Delete',
      exp: (item) => <IoRemoveCircle size={20} color="red" />,
      width: 88,
      align: 'center',
      onPress: doDelete,
    },
  ];

  let columns = [];
  if (agentType === 'MavLink') {
    columns = columnsForMavLink;
  } else if (agentType === 'ROS') {
    columns = columnsForROS;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('tableControl')}>
        <div className={cx(['button', 'icon'])} onClick={toggleCommandList}>
          <IoAddOutline size={20} />
        </div>
        {showCommandList && (
          <div className={cx('panelWrapper')}>
            <SearchCommand callback={addCommand} />
          </div>
        )}
      </div>
      <div className={cx('tableWrapper')}>
        <Table absoluteFill={false} columns={columns} data={commands} />
      </div>
    </div>
  );
};
export default CommandList;
